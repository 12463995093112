<template>
  <div class="area-edit">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dashboard/area' }">病区管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改病区</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-row>
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-width="100px" class="edit-form">
          <el-form-item label="病区名称" prop="title">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="归属医院">
            <el-select v-model="editForm.tid" placeholder="请选择归属医院">
              <el-option :label="item.title" :value="item.id" v-for="item in hospitalList" :key="item.id" :index="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('editRef')">立即修改</el-button>
            <el-button @click="backUrl">返回</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AreaEdit',
  data () {
    return {
      hospitalList: [],
      editForm: {
        title: '',
        tid: ''
      },
      editRules: {
        title: [
          { required: true, message: '请输入病区名称', trigger: 'blur' },
          { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' }
        ],
        tid: [
          { required: true, message: '请选择归属医院', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getSingleInfo()
    this.getHospitalList()
  },
  methods: {
    // 获取医院列表
    async getHospitalList () {
      const { data: res } = await this.$http.get('/hospital-list')
      if (res.meta.status === 200) {
        this.hospitalList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async getSingleInfo () {
      const { data: res } = await this.$http.get('/area/' + this.$route.params.id)
      if (res.meta.status === 200) {
        this.editForm.title = res.data.title
        this.editForm.tid = res.data.tid
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    backUrl () {
      this.$router.push('/dashboard/area')
    },
    // 提交表单
    async submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/area/' + this.$route.params.id, this.editForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.$router.push('/dashboard/area')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.page-content{
  padding: 60px;
}
</style>
